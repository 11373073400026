import {
  default as CoreMobileFirstLayout,
  MobileFirstLayoutProps,
} from "@hornet-web-react/core/components/Layout/MobileFirstLayout"

import { FC } from "react"
import AdUnit from "../Ads/AdUnit"
import styled from "styled-components"
import Menu from "./Menu"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"

const MobileFirstLayout: FC<MobileFirstLayoutProps> = (props) => {
  const { isAuthenticated } = useSessionUser()
  return (
    <CoreMobileFirstLayout
      leftPanel={
        isAuthenticated ? (
          <LeftPanel>
            <LeftPanelMenu />
            <AdUnit internalName={"WF_LR_300X250"} />
          </LeftPanel>
        ) : undefined
      }
      {...props}
    />
  )
}

export default MobileFirstLayout

const LeftPanel = styled.div`
  max-width: 100%;
`

const LeftPanelMenu = styled(Menu)`
  padding-top: ${({ theme }) => theme.spacing.regular};
`
