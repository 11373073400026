import { FC, useEffect, useRef, useState } from "react"
import {
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { AdUnitScreenSize } from "../../types"
import styled, { css } from "styled-components"
import { useIntersectionObserver } from "usehooks-ts"
import { UnstyledButtonStyle } from "@hornet-web-react/core/components/UI/UnstyledButton"
import { usePremium } from "../../hooks/use-premium"
import { PremiumPaywall } from "@hornet-web-react/core/types"
import PremiumIcon from "@hornet-web-react/core/components/Icons/PremiumIcon"
import useTranslation from "next-translate/useTranslation"
import device, { size } from "../../styles/breakpoints"
import { AdInitializeSignal, useAdUnit } from "../../hooks/use-ad-unit"
import { v4 as uuidv4 } from "uuid"

export type AdUnitProps = {
  internalName: string
  isCentered?: boolean
  isEmbedded?: boolean
}

const i18nKey = "hornet:components.ads.ad_unit"

const AdUnit: FC<AdUnitProps> = ({
  internalName,
  isCentered = false,
  isEmbedded = false,
}) => {
  const { t } = useTranslation()
  const { isInApp } = useSessionEnvironment()
  const { isAuthenticated, currentUser } = useSessionUser()
  const [hasInitialized, setHasInitialized] = useState(false)
  const visibilityRef = useRef<HTMLDivElement | null>(null)
  const visibilityEntry = useIntersectionObserver(visibilityRef, {})
  const isLoadMoreVisible = !!visibilityEntry?.isIntersecting
  const { openPremiumModal } = usePremium()
  const { unit, initialize, isReady, isDebugSlot } = useAdUnit(internalName)
  const adUnitElementId = useRef("ad-unit-" + uuidv4())
  const adUnitSignal = useRef<AdInitializeSignal | null>(null)
  const isHidden = currentUser?.hasPremium || isInApp

  // invalidate and cleanup the ad unit
  useEffect(() => {
    return () => {
      if (adUnitSignal.current) {
        // this stops the ad unit from rendering, kinda like `isMounted` classic hack
        adUnitSignal.current.invalidate()

        // relay to SDK's destroy slot logic
        adUnitSignal.current.cleanup()
      }
    }
  }, [])

  // initialize the ad unit = show it in DOM with the ID (otherwise we have hydration error)
  useEffect(() => {
    if (isLoadMoreVisible && !hasInitialized && unit && isReady && !isHidden) {
      setHasInitialized(true)
    }
  }, [isLoadMoreVisible, hasInitialized, unit, isReady, isHidden])

  // actually initialize the ad unit once is shown
  useEffect(() => {
    if (hasInitialized && !adUnitSignal.current && unit) {
      adUnitSignal.current = initialize(unit, adUnitElementId.current)
    }
  }, [hasInitialized, unit, initialize])

  if (isHidden || !unit) {
    return null
  }

  return (
    <Wrapper
      isCentered={isCentered}
      isEmbedded={isEmbedded}
      isScrolling={unit.isInfiniteScroll}
      screenSize={unit.screenSize}
      ref={visibilityRef}
    >
      <Container
        hasInitialized={hasInitialized}
        isEmbedded={isEmbedded}
        width={unit.width}
      >
        {hasInitialized ? (
          <AdSlot
            id={adUnitElementId.current}
            width={unit.width}
            height={unit.height}
          >
            {isDebugSlot ? (
              <DebugName>{`${unit.id}-${unit.screenSize}`}</DebugName>
            ) : null}
          </AdSlot>
        ) : (
          // placeholder only to prevent CLS
          <AdSlot width={unit.width} height={unit.height}>
            {isDebugSlot ? (
              <DebugName>{`${unit.id}-${unit.screenSize}`}</DebugName>
            ) : null}
          </AdSlot>
        )}
        {isAuthenticated && (
          <RemoveAds>
            <RemoveAdsButton
              onClick={() => openPremiumModal(PremiumPaywall.enum.ad_tapremove)}
            >
              <PremiumCrownIcon size={20} />
              {t(`${i18nKey}.button_label`)}
            </RemoveAdsButton>
          </RemoveAds>
        )}
      </Container>
    </Wrapper>
  )
}

export default AdUnit

const desktopStyle = css`
  @media screen and ${device.gtMd} {
    display: block;
  }
`

const tabletStyle = css`
  @media screen and ${device.gtSm} {
    display: block;
  }
`

const mobileStyle = css`
  display: block;
`

const mobileOnlyStyle = css`
  @media screen and ${`(max-width: ${size.sm - 1}px)`} {
    display: block;
  }
`

const Wrapper = styled.div<{
  isScrolling: boolean
  isCentered: boolean
  isEmbedded: boolean
  screenSize: AdUnitScreenSize
}>`
  margin-bottom: 0;
  display: none;
  margin-top: ${({ isScrolling }) => (isScrolling ? "4px" : 0)};
  margin-left: ${({ isCentered }) => (isCentered ? "auto" : 0)};
  margin-right: ${({ isCentered }) => (isCentered ? "auto" : 0)};

  ${({ screenSize }) => screenSize === "desktop" && desktopStyle};
  ${({ screenSize }) => screenSize === "tablet" && tabletStyle};
  ${({ screenSize }) => screenSize === "mobile" && mobileStyle};
  ${({ screenSize }) => screenSize === "mobileOnly" && mobileOnlyStyle};
`

const Container = styled.div<{
  hasInitialized: boolean
  isEmbedded: boolean
  width: number
}>`
  //min-width: $master-layout-side-panel-width;
  width: ${(props) => `${props.width}px`};
  margin: 0 auto;
  border: solid
    ${({ theme, isEmbedded }) =>
      isEmbedded ? 0 : `1px ${theme.color.border.default}`};
  padding: 0;
  text-align: center;
  background: ${({ hasInitialized }) => (hasInitialized ? "#eee" : "#d8d8d8")};
  overflow: hidden;
`

const AdSlot = styled.div<{ width: number; height: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  margin: 0 auto;
`

const DebugName = styled.div`
  ${({ theme }) => theme.font.bold.body};
  color: ${({ theme }) => theme.color.text.tertiary};
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const RemoveAds = styled.div`
  margin: auto;
  text-align: right;
  width: 100%;
  background: #fff;
`

const RemoveAdsButton = styled.button`
  ${UnstyledButtonStyle};

  color: ${({ theme }) => theme.color.text.tertiary};
  font-weight: normal;
  margin: 0 0 0 auto;
  text-transform: none;
  background: #fff;
  border: 0;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 8px 6px;
  outline: none;
  cursor: pointer;
  padding-left: 38px;
  position: relative;
`

const PremiumCrownIcon = styled(PremiumIcon)`
  margin-right: 4px;
`
