import {
  AdUnitConfigType,
  AdUnitMappingType,
  AdUnitScreenSize,
  AdUnitType,
} from "../types"
import appConfig from "../app-config"
import { useCallback, useState } from "react"
import { useApiLocation } from "@hornet-web-react/core/hooks/use-api-location"
import { debug } from "@hornet-web-react/core/utils"
import { useSessionEnvironment } from "@hornet-web-react/core/contexts/session"
import { useScript } from "@hornet-web-react/core/hooks/useScript"

const COUNTRY_CODES_FOR_ALT_ADS = ["ru"]

export type AdInitializeSignal = {
  isValid: boolean
  cleanup: () => void
  invalidate: () => void
}

export function useAdUnit(adUnitId: string) {
  const { country: userCountry, isCountryCodeDetected } = useApiLocation()
  const [isLoadClassicAds, setIsLoadClassicAds] = useState(false)
  const [isLoadAltAds, setIsLoadAltAds] = useState(false)
  const { cspNonce, sessionQueryParams } = useSessionEnvironment()

  useScript(
    isLoadClassicAds
      ? `https://securepubads.g.doubleclick.net/tag/js/gpt.js`
      : null,
    undefined,
    {
      removeOnUnmount: false,
    }
  )

  useScript(isLoadAltAds ? `/api/alt-ads?n=${cspNonce}` : null, undefined, {
    removeOnUnmount: false,
  })

  const isShowingAltAds =
    (userCountry && COUNTRY_CODES_FOR_ALT_ADS.includes(userCountry)) ||
    typeof sessionQueryParams["altads"] !== "undefined"
  const classicAdUnits: AdUnitType[] = getClassicAdUnits()
  const altAdUnits: AdUnitType[] = getAltAdUnits()

  const isDebugSlot = !["beta", "production"].includes(appConfig.environment)

  // NOTE: if we ever start having big-revenue direct campaigns on web, we'll need to
  // implement VPN check and set GAM targeting based on the VPN country (and not the
  // country the user is pretending to be in)
  // but we don't have these anymore and unless the programmatic revenue suffers
  // from the VPN traffic, we don't need to worry about it at the moment

  return {
    unit: findById(adUnitId, isShowingAltAds ? altAdUnits : classicAdUnits),
    isDebugSlot,
    isReady: isCountryCodeDetected,

    initialize: useCallback(
      (unit: AdUnitType, divId: string) => {
        // create a signal, that will be used to abort the ad request
        // essentially a mutable variable that will be updated with the cleanup
        // function once relevant and that the useEffect will be able to use
        const signal: AdInitializeSignal = {
          isValid: true,
          invalidate() {
            this.isValid = false
          },
          cleanup() {
            // empty op
          },
        }

        debug(
          `useAdUnit@render slotId: ${unit.id}-${unit.screenSize}, divId: ${divId}`
        )

        // no SDK loading if it's debug slot only
        if (isDebugSlot) {
          return signal
        }

        if (isShowingAltAds) {
          setIsLoadAltAds(true)
          void initializeAltAdUnit(unit, divId, signal)
          return signal
        }

        setIsLoadClassicAds(true)
        void initializeClassicAdUnit(unit, divId, signal)
        return signal
      },
      [isShowingAltAds, isDebugSlot, setIsLoadClassicAds, setIsLoadAltAds]
    ),
  }
}

const CLASSIC_AD_UNITS: AdUnitConfigType[] = [
  {
    id: "WC_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WC_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "mobile",
    isInfiniteScroll: false,
  },
  {
    id: "WF_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WF_MR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: true,
  },
  {
    id: "WF_MR_320X50",
    width: 320,
    height: 50,
    screenSize: "mobileOnly",
    isInfiniteScroll: true,
  },
  {
    id: "WF_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WF_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WG_EX_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: true,
  },
  {
    id: "WG_EX_320X50",
    width: 320,
    height: 50,
    screenSize: "mobileOnly",
    isInfiniteScroll: true,
  },
  {
    id: "WG_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WG_MR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WG_MR_320X50",
    width: 320,
    height: 50,
    screenSize: "mobileOnly",
    isInfiniteScroll: false,
  },
  {
    id: "WG_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WG_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WN_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WN_MR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WN_MR_320X50",
    width: 320,
    height: 50,
    screenSize: "mobileOnly",
    isInfiniteScroll: false,
  },
  {
    id: "WN_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WN_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WP_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WP_MR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: true,
  },
  {
    id: "WP_MR_320X50",
    width: 320,
    height: 50,
    screenSize: "mobile",
    isInfiniteScroll: true,
  },
  {
    id: "WP_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WP_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WS_LR_300X250",
    width: 300,
    height: 250,
    screenSize: "desktop",
    isInfiniteScroll: false,
  },
  {
    id: "WS_MR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: true,
  },
  {
    id: "WS_MR_320X50",
    width: 320,
    height: 50,
    screenSize: "mobileOnly",
    isInfiniteScroll: true,
  },
  {
    id: "WS_P1_300X250",
    width: 300,
    height: 250,
    screenSize: "mobile",
    isInfiniteScroll: true,
  },
  {
    id: "WS_P2_300X250",
    width: 300,
    height: 250,
    screenSize: "mobile",
    isInfiniteScroll: true,
  },
  {
    id: "WS_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WS_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WCM_RR_300X250",
    width: 300,
    height: 250,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
  {
    id: "WCM_RR_300X600",
    width: 300,
    height: 600,
    screenSize: "tablet",
    isInfiniteScroll: false,
  },
]

const ALT_AD_UNITS: AdUnitConfigType[] = [...CLASSIC_AD_UNITS]

function buildMapping(
  width: number,
  height: number,
  screenSize: AdUnitScreenSize
): AdUnitMappingType[] {
  switch (screenSize) {
    case "desktop":
      return [
        { condition: [1280, 0], sizes: [width, height] },
        { condition: [0, 0], sizes: [] },
      ]

    case "tablet":
      return [
        { condition: [960, 0], sizes: [width, height] },
        { condition: [0, 0], sizes: [] },
      ]

    case "mobile":
      return [
        { condition: [0, 0], sizes: [width, height] },
        { condition: [0, 0], sizes: [] },
      ]

    case "mobileOnly":
      return [
        { condition: [960, 0], sizes: [] },
        { condition: [0, 0], sizes: [width, height] },
      ]
  }
}

function getClassicAdUnits() {
  return CLASSIC_AD_UNITS.map(
    ({ id, width, height, screenSize, isInfiniteScroll }) => {
      return {
        id: id,
        slotId: `/194453832/${id}`,
        width: width,
        height: height,
        mapping: buildMapping(width, height, screenSize),
        screenSize,
        isInfiniteScroll,
      }
    }
  )
}

function getAltAdUnits() {
  return ALT_AD_UNITS.map(
    ({ id, width, height, screenSize, isInfiniteScroll }) => {
      let slotId =
        id.indexOf("300X600") >= 0
          ? "R-A-4258283-3"
          : id.indexOf("300X250") >= 0
          ? "R-A-4258283-2"
          : "R-A-4258283-1"

      if (["beta", "alpha"].includes(appConfig.environment)) {
        slotId = slotId.replace("4258283", "2306436")
      }

      return {
        id: id,
        slotId: slotId,
        width: width,
        height: height,
        mapping: buildMapping(width, height, screenSize),
        screenSize,
        isInfiniteScroll,
      }
    }
  )
}

function findById(id: string, units: AdUnitType[]) {
  return units.find((unit) => unit.id === id)
}

declare global {
  interface Window {
    yaContextCb: {
      push: (cb: () => void) => void
    }
    Ya: {
      Context: {
        AdvManager: {
          render: (options: { renderTo: string; blockId: string }) => void
        }
      }
    }
    googletag: {
      apiReady: boolean
      cmd: {
        push: (cb: () => void) => void
      }
      sizeMapping: () => {
        addSize: (condition: number[], sizes: number[]) => any
      }
      defineSlot: (
        slotId: string,
        sizes: number[],
        divId: string
      ) => {
        defineSizeMapping: (mapping: any) => any
        setForceSafeFrame: (force: boolean) => any
        addService: (service: any) => any
      }
      pubads: () => {
        setPrivacySettings: (settings: any) => any
        setTargeting: (key: string, value: string) => any
        enableSingleRequest: () => any
      }
      enableServices: () => any
      display: (divId: string) => any
      destroySlots: (slots: any[]) => any
    }
  }
}

async function initializeAltAdUnit(
  unit: AdUnitType,
  divId: string,
  signal: AdInitializeSignal,
  attempt = 1
) {
  debug(
    `useAdUnit@initializeAltAdUnit: slotId: ${unit.id}-${unit.screenSize}, divId: ${divId}, attempt: ${attempt}`
  )

  if (window.yaContextCb) {
    // irrelevant
    if (!signal.isValid) {
      return
    }

    window.yaContextCb.push(() => {
      window.Ya.Context.AdvManager.render({
        renderTo: divId,
        blockId: unit.slotId,
      })
    })
  } else {
    if (attempt > 10) {
      // let's not pollute our exceptions reporting with adblocks
      //     new Error(`Ad Unit error: could not show ${this.get('slotId')} due to googletag not ready`)
      // );
      return
    }

    await new Promise((r) => setTimeout(r, 500))
    return initializeAltAdUnit(unit, divId, signal, attempt + 1)
  }
}

async function initializeClassicAdUnit(
  unit: AdUnitType,
  divId: string,
  signal: AdInitializeSignal,
  attempt = 1
) {
  debug(
    `useAdUnit@initializeClassicAdUnit: slotId: ${unit.id}-${unit.screenSize}, divId: ${divId}, attempt: ${attempt}`
  )

  if (!document.querySelector(`#${divId}`)) {
    // do not even bother, perhaps user navigated away or something else is broken :troll:
    // DEBUG: good place to add some logging if we need to debug ad revenue
    return
  }

  if (window.googletag && window.googletag.apiReady) {
    // irrelevant
    if (!signal.isValid) {
      return
    }

    window.googletag.cmd.push(() => {
      // build responsive unit size mapping
      // see https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
      const unitMapping = unit.mapping
        .reduce((acc, { condition, sizes }) => {
          return acc.addSize(condition, sizes)
        }, window.googletag.sizeMapping())
        .build()

      // reference for cleanup fn
      const googleTagAdHandler = window.googletag
        .defineSlot(unit.slotId, [unit.width, unit.height], divId)
        .defineSizeMapping(unitMapping)
        // enabling `setForceSafeFrame` kills RTK (programmatic ads)
        .setForceSafeFrame(true)
        .addService(window.googletag.pubads())

      // TODO: hasPersonalizedAds = handle consent in cookie
      const hasPersonalizedAds = true

      // opt-out by user
      window.googletag.pubads().setPrivacySettings({
        childDirectedTreatment: false,
        underAgeOfConsent: false,
        limitedAds: !hasPersonalizedAds,
        nonPersonalizedAds: !hasPersonalizedAds,
        restrictDataProcessing: !hasPersonalizedAds,
      })

      // targeting params
      // only if we implement the VPN check etc, otherwise we don't need to set it
      const targetingParams: { key: string; value: string }[] = []
      if (targetingParams.length) {
        targetingParams.forEach((targeting) => {
          window.googletag.pubads().setTargeting(targeting.key, targeting.value)
          debug(
            `AdUnit@_initAd: targeting: ${targeting.key}=${targeting.value}`
          )
        })
      }

      // safe frame
      window.googletag.pubads()

      window.googletag.pubads().enableSingleRequest()
      window.googletag.enableServices()

      window.googletag.display(divId)

      signal.cleanup = () => {
        window.googletag.cmd.push(() => {
          window.googletag.destroySlots([googleTagAdHandler])
        })
      }
    })
  } else {
    if (attempt > 10) {
      // let's not pollute our exceptions reporting with adblocks
      //     new Error(`Ad Unit error: could not show ${this.get('slotId')} due to googletag not ready`)
      // );
      return
    }

    await new Promise((r) => setTimeout(r, 500))
    return initializeClassicAdUnit(unit, divId, signal, attempt + 1)
  }
}
