import styled, { css, useTheme } from "styled-components"
import { FC, PropsWithChildren, ReactNode } from "react"

export type MobileFirstLayoutProps = {
  className?: string
  backgroundColor?: string | null
  hasPadding?: boolean
  MainPanel?: FC<PropsWithChildren>
  leftPanel?: ReactNode
  rightPanel?: ReactNode
  children: ReactNode
}

const MobileFirstLayout: FC<MobileFirstLayoutProps> = ({
  className = "",
  backgroundColor,
  hasPadding,
  children,
  MainPanel,
  rightPanel,
  leftPanel,
}) => {
  const theme = useTheme()

  return (
    <>
      <style jsx global>{`
        body {
          background-color: ${backgroundColor ||
          theme.color.bg.light03} !important;
        }
      `}</style>
      <MobileFirstLayoutWrapper
        className={className}
        backgroundColor={backgroundColor || null}
      >
        {leftPanel ? <LeftAsidePanel>{leftPanel}</LeftAsidePanel> : null}

        {MainPanel ? (
          <MainPanel>{children}</MainPanel>
        ) : (
          <MobileFirstLayoutMainPanel
            hasPadding={typeof hasPadding === "undefined" || hasPadding}
          >
            {children}
          </MobileFirstLayoutMainPanel>
        )}

        {rightPanel ? <RightAsidePanel>{rightPanel}</RightAsidePanel> : null}
      </MobileFirstLayoutWrapper>
    </>
  )
}

export default MobileFirstLayout

const hasPaddingStyle = css`
  padding-bottom: ${({ theme }) => theme.spacing.regular};
`

const MobileFirstLayoutWrapper = styled.div<{
  backgroundColor: string | null
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;

  // this is our scrolling container
  // not body; and so the mobile safari navbar won't jump around on scroll
  min-height: 0;
  overflow: hidden;
  overflow-y: auto;
  flex: 1 1 auto;

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.bg.light03};

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    justify-content: center;
  }
`

export const MobileFirstLayoutMainPanel = styled.main<{ hasPadding: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100%; // always full height
  width: 100%;
  flex: 1 1 auto;

  ${(props) => props.hasPadding && hasPaddingStyle};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
    margin: 0 auto;
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    margin: 0;
  }
`

const RightAsidePanel = styled.aside`
  display: none;
  width: ${({ theme }) => theme.width.containerPanelWidth};
  position: sticky;
  top: ${({ theme }) => theme.spacing.regular};
  max-height: 100vh;
  overflow-y: auto;
  padding-left: ${({ theme }) => theme.spacing.regular};

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleRightPanel} {
    display: block;
  }
`

const LeftAsidePanel = styled.aside`
  display: none;
  width: ${({ theme }) => theme.width.containerPanelWidth};
  position: sticky;
  top: 0;
  max-height: 100vh;
  flex: 0 1 auto;
  height: 100%;
  overflow: hidden auto;
  padding-right: ${({ theme }) => theme.spacing.regular};

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: flex;
  }
`
